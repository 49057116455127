.entryContainer {
  width: 355px;
  margin: 2px 0;
}

@media only screen and (max-width: 850px) {
  .entryContainer {
    width: 100%;
  }
}

.innerEntryContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.entry {
  padding: 7px 7px;
  border-radius: 7px;
  border: 1px solid black;
  color: black;
  flex-grow: 1;
  width: 100%;
}

.inputError {
  border-color: red;
}

.entry:disabled {
  background-color: #cccccc;
}

.errorMessage {
  color: rgb(255, 144, 144);
  font-size: 12px;
}

