img.copyIcon {
    background-color: white;
    border-radius: 30px;
    width: 20px;
    padding: 5px;
    cursor: pointer;
}

img.copyIcon:hover {
    transition: background-color 0.5s ease;
    background-color: lightblue;
}

img.copyIcon:active {
    transition: background-color 0.1s linear;
    background-color: #436777;
}