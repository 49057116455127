button.actionButton {
  border: 1px solid #cccccc;
  padding: 12px 20px;
  background-color: #3d6776;
  color: white;
  border-radius: 10px;
  width: 140px;
}

button.actionButton:hover {
  background-color: #607286;
  cursor: pointer;
}