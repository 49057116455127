body {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  background: rgb(61, 103, 118);
  background: linear-gradient(rgba(61, 103, 118, 1) 0%, rgba(114, 108, 132, 1) 40%, rgba(176, 142, 154, 1) 100%);
  background-repeat: no-repeat;
  color: #dddddd;
  font-size: 18px;
  min-height: 100vh;
}

h1 {
  font-weight: 700;
}
