.pageContainer {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
}

.logoContainer {
  margin: 0 auto;
}

.logoContainer img {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.contentContainer {
  text-align: left;
}

.actionButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

@media only screen and (max-width: 850px) {
  .actionButtonContainer {
    margin-top: 10px;
    width: 100%;
  }
}

footer {
  margin-top: 50px;
  font-size: smaller;
}

footer a {
  color: white;
  text-decoration: none;
}

footer a:hover {
  color: #DDDDDD;
}

footer a:visited {
  color: white;
}