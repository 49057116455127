div.aGenerator label {
  width: 270px;
}

div.borderBox {
  border: 1px solid #dddddd;
  padding: 0px 20px 20px 20px;
  margin: 40px 10px 0 10px;
}

div.borderBox:first-child {
  margin-top: 0;
}

div.borderBox div.legend {
  display: inline-block;
  position: relative;
  top: -12px;
  left: 10px;
  background: black;
  padding-left: 10px;
  padding-right: 10px;
}

.boxGroup {
  display: flex;
  align-items: center;
  padding: 6px 0;
}

@media only screen and (max-width: 850px) {
  .boxGroup {
    flex-direction: column;
    align-items: flex-start;
  }
}
